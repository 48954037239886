<template>
  <div>

    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <tenant-detail-general-card
          v-if="tenant"
          :tenant="tenant"
        />

        <tenant-detail-admin-user-card
          v-if="tenant"
          :tenant="tenant"
        />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <tenant-detail-ou-card :tenant-id="tenantId" />
        <tenant-detail-role-card :tenant-id="tenantId" />
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <tenant-detail-feature-card :tenant-id="tenantId" />
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <tenant-detail-user-card :tenant-id="tenantId" />
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <tenant-detail-asset-card :impersonate-as-tenant="tenantId" />
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {
  BRow, BCol,
} from 'bootstrap-vue'
import TenantDetailGeneralCard from './TenantDetailGeneralCard.vue'
import TenantDetailOuCard from '@/views/shared/tenant/TenantDetailOuCard.vue'
import TenantDetailRoleCard from '@/views/shared/tenant/TenantDetailRoleCard.vue'
import TenantDetailUserCard from '@/views/shared/tenant/TenantDetailUserCard.vue'
import TenantDetailAdminUserCard from './TenantDetailAdminUserCard.vue'
import TenantDetailAssetCard from './TenantDetailAssetCard.vue'
import TenantDetailFeatureCard from './TenantDetailFeatureCard.vue'
import TenantService from '@/service/tenant.service'

export default {
  components: {
    BRow,
    BCol,
    TenantDetailOuCard,
    TenantDetailRoleCard,
    TenantDetailGeneralCard,
    TenantDetailUserCard,
    TenantDetailAdminUserCard,
    TenantDetailAssetCard,
    TenantDetailFeatureCard,
  },
  data() {
    return {
      tenant: {},
    }
  },
  computed: {
    tenantId() {
      return this.$route.params.id
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      TenantService.getAsync(this.$route.params.id).then(result => {
        this.tenant = result
      })
    },
  },
}

</script>
