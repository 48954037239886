// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'
import { buildAxiosRequestConfig, parseGetParams } from './serviceManager'

const tenantProviderName = 'T'

export default {
  async getTenantFeatureListAsync(tenantId, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = {
      providerName: tenantProviderName,
      providerKey: tenantId,
    }

    requestConfig.paramsSerializer = parseGetParams

    const response = await axios.get('/feature-management/features', requestConfig)
    return response
  },

  async updateTenantFeaturesAsync(tenantId, data, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = {
      providerName: tenantProviderName,
      providerKey: tenantId,
    }

    requestConfig.paramsSerializer = parseGetParams

    const response = await axios.put('/feature-management/features', data, requestConfig)
    return response
  },
}
