<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        class="d-flex justify-content-between flex-column"
      >
        <div class="d-flex justify-content-start">
          <b-avatar
            size="120px"
            variant="light-primary"
            :text="tenant.name | avatarText(3)"
            rounded
          />

          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ tenant.name }}
              </h4>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{ name: 'apps-users-edit', params: { id: tenant.id } }"
                variant="primary"
              >
                Edit
              </b-button>
              <b-button
                variant="outline-danger"
                class="ml-1"
              >
                Delete
              </b-button>
            </div>
          </div>

        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      />

    </b-row>
    <b-row>
      <b-col>

        <!-- User Stats -->
        <div class="d-flex align-items-center mt-2">

          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-primary"
              rounded
            >
              <feather-icon
                icon="BoxIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ tenant.assetCount }}
              </h5>
              <small>Assets</small>
            </div>
          </div>

          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-primary"
              rounded
            >
              <feather-icon
                icon="CodesandboxIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ tenant.assetWithAutosupportCount }}
              </h5>
              <small>Assets with autosupport</small>
            </div>
          </div>

          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-success"
              rounded
            >
              <feather-icon
                icon="AwardIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ tenant.assetWithProActiveServiceCount }}
              </h5>
              <small>Assets with ProActive Service</small>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>

import {
  BCard, BAvatar, BRow, BCol, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BAvatar, BRow, BCol, BButton,
  },
  props: {
    tenant: {
      type: Object,
      default: () => {},
    },
  },
}

</script>
