<template>
  <b-card title="Admins">
    <ul>
      <li
        v-for="adminUser in tenant.adminUsers"
        :key="adminUser.id"
      >
        <b-link :to="getAdminUserRoute(adminUser.id)">
          {{ adminUser.email }}
        </b-link>
      </li>
    </ul>
  </b-card>
</template>

<script>

import {
  BCard, BLink,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BCard,
    BLink,
  },
  props: {
    tenant: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      isHost: 'auth/isHost',
    }),
  },
  methods: {
    getAdminUserRoute(adminUserId) {
      const params = { id: this.tenant.id, userid: adminUserId }
      if (this.isHost) {
        return { name: 'host:manage:tenant-user-detail', params }
      }

      return { name: 'tenant:manage:tenant-user-detail', params }
    },
  },
}

</script>
