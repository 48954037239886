<template>
  <b-card
    no-body
  >
    <b-card-header>
      <b-card-title>Features</b-card-title>
    </b-card-header>
    <b-card-body
      style="max-height: 400px; overflow: auto"
    >
      <b-overlay :show="isLoading || isSaving">
        <table
          class="table table-borderless table-sm"
        >
          <tbody>
            <template v-for="featureGroup in features.groups">
              <tr
                :key="featureGroup.name"
                colspan="2"
              >
                <th>{{ featureGroup.displayName }}</th>
              </tr>
              <tr
                v-for="(feature, index) in featureGroup.features"
                :key="feature.name"
                :class="{ 'border-bottom': index === featureGroup.features.length, 'border-top': index === 0 }"
              >
                <td class="pl-5">
                  {{ feature.displayName }} <br> <small class="text-muted">{{ feature.description }}</small>
                </td>
                <td>
                  <div v-if="feature.valueType.name === 'ToggleStringValueType'">
                    <b-form-checkbox
                      switch
                      :checked="feature.value"
                      value="true"
                      unchecked-value="false"
                      :disabled="!$can('Core.Tenants.ManageFeatures')"
                      @change="featureValueChanged(feature.name, $event)"
                    />
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </b-overlay>
    </b-card-body>
  </b-card>
</template>

<script>

import {
  BCard, BCardHeader, BCardBody, BCardTitle, BFormCheckbox, BOverlay,
} from 'bootstrap-vue'

import FeatureService from '@/service/feature.service'

export default {
  components: {
    BCard, BCardHeader, BCardBody, BCardTitle, BFormCheckbox, BOverlay,
  },
  props: {
    tenantId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      features: {},
      isLoading: false,
      isSaving: false,
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.isLoading = true
      FeatureService.getTenantFeatureListAsync(this.tenantId)
        .then(result => {
          this.features = result.data
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    // eslint-disable-next-line no-unused-vars
    featureValueChanged(featureName, newVal) {
      this.isSaving = true
      // create data to update
      const data = {
        features: [
          { name: featureName, value: newVal },
        ],
      }

      FeatureService.updateTenantFeaturesAsync(this.tenantId, data)
        .then(() => {
          this.$toast.success('Updated', {
            icon: true,
          })

          this.loadData()
        })
        .catch(err => {
          this.$swal({
            title: 'Something went wrong!',
            text: err.response.data.error.message,
            icon: 'error',
          })
        })
        .finally(() => {
          this.isSaving = false
        })
    },
  },
}

</script>
